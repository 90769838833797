//create a class for the database operations
class IndexedDBHelper {
  //initialize the database name and version
  constructor(dbName, dbVersion) {
    this.dbName = dbName;
    this.dbVersion = dbVersion;
    this.db = null; //the database instance
  }

  //open the database and return a promise
  open() {
    return new Promise((resolve, reject) => {
      //request to open the database
      let openRequest = indexedDB.open(this.dbName, this.dbVersion);

      //handle success
      openRequest.onsuccess = (event) => {
        //assign the database instance
        this.db = event.target.result;
        resolve(this.db);
      };

      //handle error
      openRequest.onerror = (event) => {
        reject(event.target.error);
      };

      //handle upgrade needed
      openRequest.onupgradeneeded = (event) => {
        //get the database instance
        let db = event.target.result;
        //create an object store for storing data
        let objectStore = db.createObjectStore("storage");
      };
    });
  }

  //set an item in the database and return a promise
  async setStorageItem(item_name, item_value) {
    return new Promise((resolve, reject) => {
      //open the database if not already opened
      if (!this.db) {
        this.open().then(() => {
          //call this method again
          this.setStorageItem(item_name, item_value).then(resolve).catch(reject);
        }).catch(reject);
      } else {
        //start a transaction
        let transaction = this.db.transaction("storage", "readwrite");
        //get the object store
        let objectStore = transaction.objectStore("storage");
        //request to put the item
        let putRequest = objectStore.put(item_value, item_name);

        //handle success
        putRequest.onsuccess = (event) => {
          resolve(event.target.result);
        };

        //handle error
        putRequest.onerror = (event) => {
          reject(event.target.error);
        };
      }
    });
  }

  //get an item from the database and return a promise
  async getStorageItem(item_name) {
    return new Promise((resolve, reject) => {
      //open the database if not already opened
      if (!this.db) {
        this.open().then(() => {
          //call this method again
          this.getStorageItem(item_name).then(resolve).catch(reject);
        }).catch(reject);
      } else {
        //start a transaction
        let transaction = this.db.transaction("storage", "readonly");
        //get the object store
        let objectStore = transaction.objectStore("storage");
        //request to get the item
        let getRequest = objectStore.get(item_name);

        //handle success
        getRequest.onsuccess = (event) => {
          resolve(event.target.result);
        };

        //handle error
        getRequest.onerror = (event) => {
          reject(event.target.error);
        };
      }
    });
  }

  //delete an item from the database and return a promise
  async deleteStorageItem(item_name) {
    return new Promise((resolve, reject) => {
      //open the database if not already opened
      if (!this.db) {
        this.open().then(() => {
          //call this method again
          this.deleteStorageItem(item_name).then(resolve).catch(reject);
        }).catch(reject);
      } else {
        //start a transaction
        let transaction = this.db.transaction("storage", "readwrite");
        //get the object store
        let objectStore = transaction.objectStore("storage");
        //request to delete the item
        let deleteRequest = objectStore.delete(item_name);

        //handle success
        deleteRequest.onsuccess = (event) => {
          resolve(event.target.result);
        };

        //handle error
        deleteRequest.onerror = (event) => {
          reject(event.target.error);
        };
      }
    });
  }
}

//export the class as a module that can be imported in vue3
export default IndexedDBHelper;
