<template>
	<ExcelButton :exclude="exclude" :token="token" :url="url" :report_name="report_name" class="col s6" />
</template>

<script>
import ExcelButton from '@/components/ExcelButton.vue'
//import WordButton from '@/components/WordButton.vue'

export default {
	name: 'DownloadReports',
	props: ['exclude','token','url','report_name'],
	components: {
		ExcelButton, 
		//WordButton
	},
}

</script>