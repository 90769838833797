<template>
	<img src="@/../public/img/icons/excel3.png" height="50" v-on:click="getData" style="cursor:pointer;" />
	<Requests :token="token" :key="my_key3" request_type="GET" :url="url" :input_data="[]" notification_type="modal" @response="getResponse" v-if="operation=='get-data' && click_me==true" />
</template>

<script>
import * as XLSX from 'xlsx';
import Requests from '@/components/Requests.vue'

export default {
	name: 'ExcelButton',
	props: ['exclude','token','url','report_name'],
	components: {
		Requests
	},
	data() {
        return {
			excel_data:[],
			click_me: false,
			my_key3: 0,
			operation: 'get-data',
			report_name:this.report_name
        };
    },
    methods: {	
		getResponse(response){
			this.response = response;
			if('data' in response)
			{
				if('output' in response.data)
				{
					let output = response.data.output;
					let excel_data = [];
					let arr = {};
					let a = 0;
					let b = 0;
					let akeys;
					for(a=0; a<output.length; a++)
					{
						arr = output[a];
						akeys = Object.keys(arr);
						for(b=0; b<akeys.length; b++)
						{
							if(this.exclude.includes(akeys[b]))
							{
								delete arr[akeys[b]];
							}
						}
						
						excel_data[a] = arr;
					}
					
					this.excel_data = excel_data;
					if(this.click_me==true)
					{
						this.downloadExcel();
						this.click_me = false;
					}
				}
			}
		},
		getData(){
			this.my_key3 = this.my_key3+1;
			this.click_me = true;
			console.log(this.my_key3);
		},
		downloadExcel(){
			const data = XLSX.utils.json_to_sheet(this.excel_data)
			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, data, 'data')
			XLSX.writeFile(wb,this.report_name+'.xlsx')
		}
	}
}
</script>