import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import('../views/Recover.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/guides',
    name: 'guides',
    component: () => import('../views/Guides.vue'), 
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register/BasicInfo.vue')
  },   
  {
    path: '/register/user_org',
    name: 'register.user_org',
    component: () => import('../views/Register/UserOrg.vue')
  },
  {
    path: '/register/signature_stamp',
    name: 'register.signature_stamp',
    component: () => import('../views/Register/SignatureStamp.vue')
  },
  {
    path: '/register/assignment_letter',
    name: 'register.assignment_letter',
    component: () => import('../views/Register/AssignmentLetter.vue')
  },
  {
    path: '/register/finalize',
    name: 'register.finalize',
    component: () => import('../views/Register/Finish.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/verify_eco',
    name: 'verify_eco',
    component: () => import('../views/VerifyECO.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/Notifications.vue')
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('../views/Accounts/Accounts.vue'), 
  },	
  {
    path: '/accounts/permissions',
    name: 'accounts.permissions',
    component: () => import('../views/Accounts/Permissions/Home.vue'), 
  },
  {
    path: '/accounts/users',
    name: 'accounts.users',
    component: () => import('../views/Accounts/Users/Home.vue'), 
  },
  {
    path: '/accounts/roles',
    name: 'accounts.roles',
    component: () => import('../views/Accounts/Roles/Home.vue'), 
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('../views/Organizations/Organizations.vue'), 
  },	
  {
    path: '/organizations/branches',
    name: 'organizations.branches',
    component: () => import('../views/Organizations/Branches/Home.vue'), 
  },
  {
    path: '/organizations/orgs',
    name: 'organizations.orgs',
    component: () => import('../views/Organizations/Orgs/Home.vue'), 
  },	
  {
    path: '/organizations/org_types',
    name: 'organizations.org_types',
    component: () => import('../views/Organizations/OrgTypes/Home.vue'), 
  },		
  {
    path: '/organizations/access_tokens',
    name: 'organizations.access_tokens',
    component: () => import('../views/Organizations/AccessTokens/Home.vue'), 
  },	
  {
    path: '/exporters',
    name: 'exporters',
    component: () => import('../views/Exporters/Home.vue'), 
  },
  {
    path: '/exporters/applications',
    name: 'exporters.applications',
    component: () => import('../views/Exporters/Applications/Home.vue'), 
  },
  {
	path: '/exporters/applications/add',
	name: 'exporters.applications.pre',
	component: () => import('../views/Exporters/Applications/Add/PreStep.vue'),
  },   
  {
	path: '/exporters/applications/add/amendment',
	name: 'exporters.applications.amendment',
	component: () => import('../views/Exporters/Applications/Add/Amendment.vue'),
  },
  {
	path: '/exporters/applications/add/renewal',
	name: 'exporters.applications.renewal',
	component: () => import('../views/Exporters/Applications/Add/Renewal.vue'),
  },
  {
	path: '/exporters/applications/add/init',
	name: 'exporters.applications.0',
	component: () => import('../views/Exporters/Applications/Add/Application.vue'),
  },
  {
    path: '/exporters/products',
    name: 'exporters.products',
    component: () => import('../views/Exporters/Products/Home.vue'), 
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: () => import('../views/Certificates/Home.vue'), 
  },
  {
    path: '/certificates/verification',
    name: 'certificate.verification',
    component: () => import('../views/Certificates/VerificationHome.vue'), 
  },
  {
    path: '/certificates/use_step1',
    name: 'certificate.use.1',
    component: () => import('../views/Certificates/Use/Step1.vue'), 
  },
  {
    path: '/certificates/use_step2',
    name: 'certificate.use.2',
    component: () => import('../views/Certificates/Use/Step2.vue'), 
  },
  {
    path: '/certificates/verification/use',
    name: 'certificate.verification.use.1',
    component: () => import('../views/Certificates/Add/Step1.vue'), 
  },
  {
    path: '/certificates/application',
    name: 'certificate.application',
    component: () => import('../views/Certificates/ApplicationHome.vue'), 
  },
  {
	path: '/certificates/application/add',
	name: 'certificates.pre',
	component: () => import('../views/Certificates/Add/PreStep.vue'),
  }, 
  {
	path: '/certificates/application/add/init',
	name: 'certificates.0',
	component: () => import('../views/Certificates/Add/Step0.vue'),
  },
  {
	path: '/certificates/application/add/1',
	name: 'certificates.1',
	component: () => import('../views/Certificates/Add/Step1.vue'),
  },
  {
    path: '/certificates/application/add/2',
    name: 'certificates.2',
    component: () => import('../views/Certificates/Add/Step2.vue')
  },
  {
    path: '/certificates/application/add/3',
    name: 'certificates.3',
    component: () => import('../views/Certificates/Add/Step3.vue')
  },
  {
    path: '/certificates/application/add/review',
    name: 'certificates.review',
    component: () => import('../views/Certificates/Add/Step4.vue')
  },
  {
    path: '/importers',
    name: 'importers',
    component: () => import('../views/Importers/Home.vue'), 
  },
  {
	path: '/importers/view',
	name: 'importers.view',
	component: () => import('../views/Importers/View.vue'),
  },
  {
	path: '/importers/list',
	name: 'importers.list',
	component: () => import('../views/Importers/List.vue'),
  },
  {
    path: '/importers/products',
    name: 'importers.products',
    component: () => import('../views/Importers/Products/Home.vue'), 
  },
  {
	path: '/importers/products/view',
	name: 'importers.products.view',
	component: () => import('../views/Importers/Products/View.vue'),
  },
  {
    path: '/designated_authorities',
    name: 'designated_authorities',
    component: () => import('../views/DesignatedAuthorities/Home.vue'), 
  },
  {
    path: '/customs',
    name: 'customs',
    component: () => import('../views/Customs/Home.vue'), 
  },
  {
    path: '/api_keys',
    name: 'api_keys',
    component: () => import('../views/API/API.vue'), 
  },	
  {
    path: '/api_keys/permissions',
    name: 'api_keys.permissions',
    component: () => import('../views/API/Permissions/Home.vue'), 
  },
  {
    path: '/api_keys/keys',
    name: 'api_keys.keys',
    component: () => import('../views/API/Keys/Home.vue'), 
  },
  {
    path: '/api_keys/subscriptions',
    name: 'api_keys.subscriptions',
    component: () => import('../views/API/Subscriptions/Home.vue'), 
  },
  {
    path: '/other',
    name: 'other',
    component: () => import('../views/other/Home.vue'), 
  },
  {
    path: '/other/guides',
    name: 'other.guides',
    component: () => import('../views/other/Guides.vue'), 
  },
  {
    path: '/other/hs_codes',
    name: 'other.hs_codes',
    component: () => import('../views/other/HSCodes.vue'), 
  },
  {
    path: '/other/units',
    name: 'other.units',
    component: () => import('../views/other/Units.vue'), 
  },
  {
    path: '/other/countries',
    name: 'other.countries',
    component: () => import('../views/other/Countries.vue'), 
  },
  {
    path: '/other/currencies',
    name: 'other.currencies',
    component: () => import('../views/other/Currencies.vue'), 
  },
  {
    path: '/other/origin_criteria',
    name: 'other.origin_criteria',
    component: () => import('../views/other/OriginCriteria.vue'), 
  },
  {
    path: '/other/intended_markets',
    name: 'other.intended_markets',
    component: () => import('../views/other/IntendedMarkets.vue'), 
  },
  {
    path: '/other/news',
    name: 'other.news',
    component: () => import('../views/other/News.vue'), 
  },
  {
    path: '/other/package_kinds',
    name: 'other.package_kinds',
    component: () => import('../views/other/PackageKind.vue'), 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
