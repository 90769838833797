<template>
	  <div class="parallax-container valign-wrapper">
		<div class="section no-pad-bot">
		  <div class="container">
			<div class="row center">
			  <img src="@/../public/img/flags.fw.png" class="responsive-img">
			</div>
		  </div>
		</div>
		<div class="parallax"><img src="@/../public/img/COMESA-digital-banner_2.jpg" alt="Unsplashed background img 1"></div>
	  </div>
	<footer class="page-footer grey lighten-4">
		<div class="row">
			<div class="col s12 center" style="margin-top:15px; margin-bottom:15px;">
				<h6 class="grey-text">© {{ current_year }} {{ languages[0] }}</h6>
			</div>
		</div>
		<div class="footer-copyright black" >
			<div class="container center grey-text lighten-2">
				<div class="row">
					<div class="col s8 offset-s2 m6 offset-m3 l8 offset-l2 center" style="margin-top:20px;">
						<div class="col s12 m12 l4 grey black-text" style="padding:7px;">
							<a class="col s12 z-depth-1 white black-text hoverable" href="#" v-on:click="changeLanguage('en')">English</a>
						</div>
						<div class="col s12 m12 l4 grey black-text" style="padding:7px;">
							<a class="col s12 z-depth-1 white black-text hoverable" href="#" v-on:click="changeLanguage('ar')">العربية</a>
						</div>
						<div class="col s12 m12 l4 grey black-text" style="padding:7px;">
							<a class="col s12 z-depth-1 white black-text hoverable" href="#" v-on:click="changeLanguage('fr')">Français</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import M from '@cutls/materialize-css';
import IndexedDBHelper from '@/assets/js/IndexedDB'

import language from '@/configs/language.json';


export default {
	name: 'AppFooter',
	props: {
		msg: String
	},
	data() {
		
        return {
			db: null,
			current_year: '2023',
			mylang: 'en',
			languages: [],
			
        };
    },
    methods: {	
		async initFunctions(){
			let mylang = await this.db.getStorageItem("mylang");
			if(mylang=="" || mylang==null || mylang===undefined)
			{
				mylang = 'en';
			}
			this.mylang = mylang;
			this.languages = language[mylang].comp__appfooter;
		},
		changeLanguage(mylang) {
			this.db.setStorageItem("mylang", mylang);
			location.reload();
		},
		setCurrentYear() {
			this.current_year = new Date().getFullYear();
		}
	},
	async mounted () {
		M.AutoInit();
		this.initFunctions();
		this.setCurrentYear();
	},
	async created() { this.db = new IndexedDBHelper("my-eco-db", 1); await this.db.open(); }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
