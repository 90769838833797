<template>
	<div class="row">
		<div class="col s12 " style="padding:10px; margin:10px;">
			<div class="col s12">
				<div class="col s12">
					  <div class="row">
							<form class="col s12 card-panel blue darken-3">
							  <div class="row">
								<div class="col s12">
								  <h4><b>{{ languages[0] }}</b></h4>
								</div>
							  </div>
							  <div class="row">
								  <div class="col s12">
									<Requests :response_status="false" :key="my_key" :local_sess_data="[]" :request_type="request_type" :url="my_url" :input_data="input_data" :notification_type="notification_type" @response="getResponse" v-if="input_data.length>0" />
									<Requests :response_status="false" :key="my_key2" :local_sess_data="[]" :request_type="request_type" :url="my_url2" :input_data="input_data2" :notification_type="notification_type" @response="getResponse2" v-if="input_data2.length>0" />
									
									  
									  <div class="col s12 white darken-3" v-if="login_test">
										<div class="input-field col s12">
										  <input v-model="user_email" id="first_name" type="email" class="validate">
										  <label for="first_name" class="blue-grey-text text-darken-4">{{ languages[1] }}</label>
										</div>
										<div class="input-field col s12">
										  <input v-model="user_password" @keyup.enter="signIn()" id="last_name" type="password" class="validate">
										  <label for="last_name" class="blue-grey-text text-darken-4">{{ languages[2] }}</label>
										</div>
										  <div class="row">
											<div class="col s12">
												<a v-on:click="signIn()" class="waves-effect red darken-2 waves-red btn-large white-text right">{{ languages[3] }}</a>
											</div>
										  </div>
										  <div class="row">
											<div class="col s12">
												<span class="right">{{ languages[7] }} <router-link to="/recovery" class="right green-text text-darken-4">&nbsp;<b>{{ languages[4] }}</b></router-link></span><br>
												<span class="right">{{ languages[8] }} <router-link to="/register" class="right red-text text-darken-4">&nbsp;<b>{{ languages[5] }}</b></router-link></span>
											</div>
										  </div>
									  </div>
									  
									  
									  <div class="col s12 white darken-3" v-if="otp_test">
										<div class="input-field col s12">
										  <input v-model="user_email" id="first_name2" type="email" class="validate" disabled>
										</div>
										<div class="input-field col s12">
										  <input v-model="user_otp" id="otp" type="text" class="validate" @keyup.enter="otpSignIn()">
										  <label for="otp" class="blue-grey-text text-darken-4">OTP</label>
										</div>
										  <div class="row">
											<div class="col s12">
												<a v-on:click="otpSignIn()" class="waves-effect red darken-2 waves-light btn-large white-text right">{{ languages[3] }}</a>
											</div>
										  </div>
										  <div class="row">
											<div class="col s12">
												<span class="right">{{ languages[7] }} <router-link to="/recovery" class="right green-text text-darken-4">&nbsp;<b>{{ languages[4] }}</b></router-link></span><br>
												<span class="right">{{ languages[8] }} <router-link to="/register" class="right red-text text-darken-4">&nbsp;<b>{{ languages[5] }}</b></router-link></span>
											</div>
										  </div>
									  </div>
									  
									  
									  <div class="col s12 white lighten-4" v-if="post_response">
										<p>&nbsp;<br><b>{{ user_err }}</b></p>
									  </div>
									  <div class="col s12 white lighten-4" v-if="error_response">
										<a v-on:click="resetLogin()" class="waves-effect red darken-2 waves-light btn-large white-text">{{ languages[6] }}</a>
										<br>&nbsp;<br>
										<div class="row">
											<div class="col s12">
												<span>{{ languages[7] }} <router-link to="/recovery" class="green-text text-darken-4">&nbsp;<b>{{ languages[4] }}</b></router-link></span><br>
												<span>{{ languages[8] }} <router-link to="/register" class="red-text text-darken-4">&nbsp;<b>{{ languages[5] }}</b></router-link></span>
											</div>
										</div>
									  </div>
								  </div>
							  </div>
							</form>
					  </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import router from '@/router'
import axios from 'axios'
import M from '@cutls/materialize-css';
import IndexedDBHelper from '@/assets/js/IndexedDB'

import Requests from '@/components/Requests.vue'
import Configs from '@/assets/js/Configs'
import apis from '@/configs/apis.json';
import countries from '@/configs/countries.json';
import comesa_countries from '@/configs/comesa_countries.json';
import language from '@/configs/language.json';

export default {
  name: 'Login',
  components: {
    Requests
  },
  data() {return {db: null, //the database helper instance
      my_key: 0,
      my_key2: 0,
      request_type: 'POST',
      notification_type: 'modal',
      user_email: "",
      user_password: "",
      user_otp: "",
      user_err: "",
      pre_response: true,
      during_response: false,
      post_response: false,
      otp_test: false,
      login_test: true,
      error_response: false,
      input_data: [],
      input_data2: [],
      my_url: '/add/users/sessions/get_otp',
      my_url2: '/add/users/sessions/otp_start',
	  mylang: 'en',
	  languages: [],
    };
  },
  async created() { this.db = new IndexedDBHelper("my-eco-db", 1); await this.db.open(); }, async mounted () {
    M.AutoInit();
	this.initFunctions();
  },
  methods: {	
	async initFunctions(){
		let mylang = await this.db.getStorageItem("mylang");
		if(mylang=="" || mylang==null || mylang===undefined)
		{
			mylang = 'en';
		}
		this.mylang = mylang;
		this.languages = language[mylang].comp__login;
	},
    tabSelector(tab) {
      this.$emit('curr_tab', tab);
    },
    resetLogin() {
		this.pre_response = true;
		this.during_response = false;
		this.post_response = false;
		this.error_response = false;
		this.user_email = '';
		this.user_password = '';
		this.my_users = '';
    },
    async getResponse(response) {
		this.pre_response = true;
		let my_res = response;
		
		console.log("my res check x1: ");
		console.log(my_res);
		
		if (my_res.data != null && my_res.data != undefined && my_res.data != "") {
			if (my_res.data.status == 201 || my_res.data.status == 200) 
			{
				this.otp_test = true;
				this.login_test = false;
		
				this.user_err = "Please check your email for your OTP.";

				this.pre_response = false;
				this.during_response = false;
				this.post_response = true;
				this.error_response = false;
			} 
			else 
			{
				this.pre_response = false;
				this.during_response = false;
				this.post_response = true;
				this.error_response = true;

				this.user_err = "Error generating your OTP. Please try again.";
				setTimeout(() => {
					this.pre_response = true;
					this.during_response = false;
					this.post_response = false;
					this.error_response = false;
					this.user_email = '';
					this.user_password = '';
					this.my_users = '';
					
					this.otp_test = false;
					this.login_test = true;
				}, 3000);
			}
		}
    },
    async getResponse2(response) {
      this.pre_response = true;
      let my_res = response;
      
	  console.log("my res check x1: ");
      console.log(my_res);
	  
	  if (my_res.data !== null && my_res.data !== undefined && my_res.data !== "") {
		if ('token' in my_res.data && 'user' in my_res.data) {
		  localStorage.setItem("token", my_res.data.token);

		  await this.db.setStorageItem("token", my_res.data.token);
		  await this.db.setStorageItem("current_user", my_res.data.user);
		  await this.db.setStorageItem("allow_exporter_registration", my_res.data.allow_exporter_registration);
		  await this.db.setStorageItem("allow_certificate_application", my_res.data.allow_certificate_application);
		  await this.db.setStorageItem("current_user_permissions", my_res.data.permissions);
		  await this.db.setStorageItem("my_countries", my_res.data.countries);
		  await this.db.setStorageItem("my_hs_codes", my_res.data.hs_codes);
		  await this.db.setStorageItem("my_units", my_res.data.units);
		  await this.db.setStorageItem("my_currencies", my_res.data.currencies);
		  await this.db.setStorageItem("my_origin_criteria", my_res.data.origin_criteria);
		  await this.db.setStorageItem("my_intended_markets", my_res.data.intended_markets);
		  await this.db.setStorageItem("my_package_kinds", my_res.data.package_kinds);
		  
		  this.user_err = my_res.data.message;
		  
		  this.otp_test = false;
		  this.login_test = false;
		
		  this.pre_response = false;
		  this.during_response = false;
		  this.post_response = true;
		  this.error_response = false;

		  setTimeout(function () {
			this.otp_test = false;
			this.login_test = false;
		
			router.push('/dashboard');
		  }, 2000);
		}
		else 
		{
		  this.pre_response = false;
		  this.during_response = false;
		  this.post_response = true;
		  this.error_response = true;

		  this.user_err = "Error";
		  setTimeout(() => {
			this.pre_response = true;
			this.during_response = false;
			this.post_response = false;
			this.error_response = false;
			this.user_email = '';
			this.user_otp = '';
			this.my_users = '';
		  }, 2000);
		}
	  }
    },
    signIn() {
		this.pre_response = false;
		let input_data = [];
		input_data.push({ email: this.user_email, password: this.user_password });
		this.input_data = input_data;
		this.my_key = this.my_key + 1;

    },
    otpSignIn(){
		this.pre_response = false;
		let input_data2 = [];
		input_data2.push({ email: this.user_email, otp: this.user_otp });
		this.input_data2 = input_data2;
		this.my_key2 = this.my_key2 + 1;

		setTimeout(() => {
			this.otp_test = false;
			this.login_test = true;
		}, 2000);
    }
  },
}
</script>

