import IndexedDBHelper from '@/assets/js/IndexedDB'

export default class CurrentUser {
	constructor(limit=512) {
		this.limit = limit*1000;
		this.db = new IndexedDBHelper("my-eco-db", 1); //the database helper instance
	}

	async account() {
		let dia_link_display;
		let curr_user = await this.db.getStorageItem("current_user");
		
		if(curr_user != null && curr_user != "")
		{
			
		}
		else
		{
			curr_user = {};
			curr_user["email"] = "";
			curr_user["first_name"] = "";
			curr_user["last_name"] = "";
			curr_user["user_role"] = "";
		}
		
		return curr_user;
	}

	async diaLinkDisplay() {
		let dia_link_display;
		let curr_user = await this.db.getStorageItem("current_user");
		
		if(curr_user != null && curr_user != "")
		{
			
			if(parseInt(curr_user.user_role)<50)
			{
				dia_link_display = true;
			}
			else
			{
				dia_link_display = false;
			}
		}
		else
		{
			dia_link_display = false;
		}
		return dia_link_display;
	}
	
	async permissions() {
		let exporter_perms;
		let user_perms;
		let org_perms;
		let certificate_perms;
		let other_perms;
		let api_perms;
		
		exporter_perms = {};
		user_perms = {};
		org_perms = {};
		certificate_perms = {};
		other_perms = {};
		api_perms = {};
		
		exporter_perms.applicants = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		exporter_perms.applications = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		exporter_perms.contact_persons = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		exporter_perms.documents = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		exporter_perms.producer_manufacturer = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		exporter_perms.products = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		exporter_perms.raw_materials = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		exporter_perms.user_exporters = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		
		user_perms.accounts = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		user_perms.org_users = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		user_perms.password = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		user_perms.permissions = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		user_perms.roles = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		user_perms.sessions = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		user_perms.user_org = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		
		org_perms.branches = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		org_perms.orgs = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		org_perms.org_types = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		
		certificate_perms.documents = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		certificate_perms.general_information = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		certificate_perms.application = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		certificate_perms.verification = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		certificate_perms.generate = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		certificate_perms.products = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		
		other_perms.countries = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.currencies = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.guides = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.hs_codes = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.news = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.origin_criteria = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.units = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.intended_markets = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		other_perms.package_kinds = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		
		api_perms.keys = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		api_perms.permissions = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		api_perms.roles = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		api_perms.subscriptions = {add_data: '0', get_data: '0', edit_data: '0', delete_data: '0'};
		
		let perms = await this.db.getStorageItem("current_user_permissions");
		
		if(perms != null && perms != "")
		{
			
			let a=0;
			
			for(a=0; a<perms.length; a++)
			{
				//console.log(perms[a]);
				
				if(perms[a].app=="*")
				{
					exporter_perms.applicants = perms[a];
					exporter_perms.applications = perms[a];
					exporter_perms.contact_persons = perms[a];
					exporter_perms.documents = perms[a];
					exporter_perms.producer_manufacturer = perms[a];
					exporter_perms.products = perms[a];
					exporter_perms.raw_materials = perms[a];
					exporter_perms.user_exporters = perms[a];
					
					user_perms.accounts = perms[a];
					user_perms.org_users = perms[a];
					user_perms.password = perms[a];
					user_perms.permissions = perms[a];
					user_perms.roles = perms[a];
					user_perms.sessions = perms[a];
					user_perms.user_org = perms[a];
					
					org_perms.branches = perms[a];
					org_perms.orgs = perms[a];
					org_perms.org_types = perms[a];
					
					certificate_perms.documents = perms[a];
					certificate_perms.general_information = perms[a];
					certificate_perms.application = perms[a];
					certificate_perms.verification = perms[a];
					certificate_perms.generate = perms[a];
					certificate_perms.products = perms[a];
					
					other_perms.countries = perms[a];
					other_perms.currencies = perms[a];
					other_perms.guides = perms[a];
					other_perms.hs_codes = perms[a];
					other_perms.news = perms[a];
					other_perms.origin_criteria = perms[a];
					other_perms.intended_markets = perms[a];
					other_perms.units = perms[a];
					other_perms.package_kinds = perms[a];
					
					api_perms.keys = perms[a];
					api_perms.permissions = perms[a];
					api_perms.roles = perms[a];
					api_perms.subscriptions = perms[a];
				}
				else
				{
				
					if(perms[a].feature=="*")
					{
						if(perms[a].app=="exporters")
						{
							exporter_perms.applicants = perms[a];
							exporter_perms.applications = perms[a];
							exporter_perms.contact_persons = perms[a];
							exporter_perms.documents = perms[a];
							exporter_perms.producer_manufacturer = perms[a];
							exporter_perms.products = perms[a];
							exporter_perms.raw_materials = perms[a];
							exporter_perms.user_exporters = perms[a];
						}
						
						if(perms[a].app=="users")
						{
							user_perms.accounts = perms[a];
							user_perms.org_users = perms[a];
							user_perms.password = perms[a];
							user_perms.permissions = perms[a];
							user_perms.roles = perms[a];
							user_perms.sessions = perms[a];
							user_perms.user_org = perms[a];
						}
						
						
						if(perms[a].app=="organizations")
						{
							org_perms.branches = perms[a];
							org_perms.orgs = perms[a];
							org_perms.org_types = perms[a];
							//console.log(perms[a]);
						}
						
						if(perms[a].app=="certificates")
						{
							certificate_perms.documents = perms[a];
							certificate_perms.general_information = perms[a];
							certificate_perms.application = perms[a];
							certificate_perms.verification = perms[a];
							certificate_perms.generate = perms[a];
							certificate_perms.products = perms[a];
						}
						
						if(perms[a].app=="other")
						{
							other_perms.countries = perms[a];
							other_perms.currencies = perms[a];
							other_perms.guides = perms[a];
							other_perms.hs_codes = perms[a];
							other_perms.news = perms[a];
							other_perms.origin_criteria = perms[a];
							other_perms.intended_markets = perms[a];
							other_perms.units = perms[a];
							other_perms.package_kinds = perms[a];
						}
						
						if(perms[a].app=="apis")
						{
							api_perms.keys = perms[a];
							api_perms.permissions = perms[a];
							api_perms.roles = perms[a];
							api_perms.subscriptions = perms[a];
						}
					}
					else
					{
						if(perms[a].app=="exporters" && perms[a].feature=="applicants")
						{
							exporter_perms.applicants = perms[a];
						}
						
						if(perms[a].app=="exporters" && perms[a].feature=="applications")
						{
							exporter_perms.applications = perms[a];
						}
						
						if(perms[a].app=="exporters" && perms[a].feature=="contact_persons")
						{
							exporter_perms.contact_persons = perms[a];
						}
						
						if(perms[a].app=="exporters" && perms[a].feature=="documents")
						{
							exporter_perms.documents = perms[a];
						}
						
						if(perms[a].app=="exporters" && perms[a].feature=="producer_manufacturer")
						{
							exporter_perms.producer_manufacturer = perms[a];
						}
						
						if(perms[a].app=="exporters" && perms[a].feature=="products")
						{
							exporter_perms.products = perms[a];
						}
						
						if(perms[a].app=="exporters" && perms[a].feature=="raw_materials")
						{
							exporter_perms.raw_materials = perms[a];
						}
						
						if(perms[a].app=="exporters" && perms[a].feature=="user_exporters")
						{
							exporter_perms.user_exporters = perms[a];
						}
						
						
						
						if(perms[a].app=="users" && perms[a].feature=="accounts")
						{
							user_perms.accounts = perms[a];
						}
						
						if(perms[a].app=="users" && perms[a].feature=="org_users")
						{
							user_perms.org_users = perms[a];
						}
						
						if(perms[a].app=="users" && perms[a].feature=="password")
						{
							user_perms.password = perms[a];
						}
						
						if(perms[a].app=="users" && perms[a].feature=="permissions")
						{
							user_perms.permissions = perms[a];
						}
						
						if(perms[a].app=="users" && perms[a].feature=="roles")
						{
							user_perms.roles = perms[a];
						}
						
						if(perms[a].app=="users" && perms[a].feature=="sessions")
						{
							user_perms.sessions = perms[a];
						}
						
						if(perms[a].app=="users" && perms[a].feature=="user_org")
						{
							user_perms.user_org = perms[a];
						}
						
						
						
						if(perms[a].app=="organizations" && perms[a].feature=="branches")
						{
							org_perms.branches = perms[a];
						}
						
						
						if(perms[a].app=="organizations" && perms[a].feature=="orgs")
						{
							org_perms.orgs = perms[a];
						}
						
						
						if(perms[a].app=="organizations" && perms[a].feature=="org_types")
						{
							org_perms.org_types = perms[a];
						}
						
						
						
						if(perms[a].app=="certificates" && perms[a].feature=="documents")
						{
							certificate_perms.documents = perms[a];
						}
						
						if(perms[a].app=="certificates" && perms[a].feature=="general_information")
						{
							certificate_perms.general_information = perms[a];
						}
						
						if(perms[a].app=="certificates" && perms[a].feature=="application")
						{
							certificate_perms.application = perms[a];
						}
						
						if(perms[a].app=="certificates" && perms[a].feature=="verification")
						{
							certificate_perms.verification = perms[a];
						}
						
						if(perms[a].app=="certificates" && perms[a].feature=="generate")
						{
							certificate_perms.generate = perms[a];
						}
						
						if(perms[a].app=="certificates" && perms[a].feature=="products")
						{
							certificate_perms.products = perms[a];
						}
						
						
						
						if(perms[a].app=="other" && perms[a].feature=="countries")
						{
							other_perms.countries = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="currencies")
						{
							other_perms.currencies = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="guides")
						{
							other_perms.guides = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="hs_codes")
						{
							other_perms.hs_codes = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="news")
						{
							other_perms.news = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="origin_criteria")
						{
							other_perms.origin_criteria = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="intended_markets")
						{
							other_perms.intended_markets = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="units")
						{
							other_perms.units = perms[a];
						}
						
						if(perms[a].app=="other" && perms[a].feature=="package_kinds")
						{
							other_perms.package_kinds = perms[a];
						}
						
						
						
						if(perms[a].app=="apis" && perms[a].feature=="keys")
						{
							api_perms.keys = perms[a];
						}
						
						if(perms[a].app=="apis" && perms[a].feature=="permissions")
						{
							api_perms.permissions = perms[a];
						}
						
						if(perms[a].app=="apis" && perms[a].feature=="roles")
						{
							api_perms.roles = perms[a];
						}
						
						if(perms[a].app=="apis" && perms[a].feature=="subscriptions")
						{
							api_perms.subscriptions = perms[a];
						}
					}
				}
			}
		}
		
		//console.log(exporter_perms);
		
		return {exporters:exporter_perms,users:user_perms,orgs:org_perms,certificates:certificate_perms,apis:api_perms,other:other_perms};
	}
}