<template>
		<ul class="row" style="line-height: 2.7;">
			<li class="col s12" v-on:click="toggleMenu()" style="cursor:pointer; line-height: 1;" v-if="mynav==false">
				<div class="col s12">
					<i :class="font_color+' material-icons right'" v-if="menu_status=='open'" style="margin-top:15px; margin-bottom:15px;">chevron_left</i>
					<i :class="font_color+' material-icons right'" style="margin-top:15px; margin-bottom:15px;" v-else>chevron_right</i>
				</div>
			</li>
			<li class="col s12" style="font-size:12px; line-height: normal;" v-if="menu_status=='open' || mynav==true">
				<div class="row">
					<span :class="font_color2+' col s12'" v-if="curr_user != null && token != null">
						<b :class="font_color" style="line-height: 1.3;">{{ languages[0] }}:</b><br>
						<span :class="font_color2" v-if="curr_user.first_name">{{ curr_user.first_name }}</span>
						<span :class="font_color2" v-if="curr_user.last_name"> {{ curr_user.last_name }}</span>
						<span :class="font_color2" v-if="curr_user.email"> - {{ curr_user.email }}</span>
						<br>
						<span :class="font_color2" v-if="curr_user.user_type">{{ curr_user.user_type }}</span>
						<span :class="font_color2" v-if="curr_user.org">
							<span :class="font_color2" v-if="curr_user.org.org_name"> {{ languages[1] }} {{ curr_user.org.org_name }}</span>
						</span>
						<span :class="font_color2" v-if="curr_user.country">, {{ curr_user.country }}</span>
					</span>
				</div>
			</li>
			<li class="col s12 divider blue-grey darken-3"></li>
			<li class="left" v-if="curr_user != null && token !=null"><router-link to="/profile" :class="font_color+option_hover+' sidemynav-close col s12'" data-position="right" data-tooltip="PROFILE"><i :class="font_color+' material-icons right'" :style="link_padding+' width:40px; '">person</i></router-link></li>
			<li class="left"><router-link to="/dashboard" :class="'sidemynav-close col s12'+option_hover" data-position="right" data-tooltip="DASHBOARD"><i :class="font_color+' material-icons right'" :style="link_padding+' width:40px; '">dashboard</i></router-link></li>
			<li class="col s12 divider blue-grey darken-3"></li>
			<li class="col s12">
				<div class="row">
					<ul class="collapsible col s12" :style="'background-color:'+bg_color+'; border-style: solid; border-color: '+bg_color+';'">
						<li v-if="show_exporters_link || show_certificate_application_link || show_certificate_verification_link || dia_link_display">
							<div class="collapsible-header" :style="'background-color:'+bg_color+'; border-style: solid; border-color: '+bg_color+';'"><a href="#" :class="option_hover" data-position="right" data-tooltip="ECO OPERATIONS"><b :class="font_color">{{ languages[3] }} <span v-if="menu_status=='open' || mynav==true"> {{ languages[4] }}</span></b></a></div>
							<div :class="'collapsible-body row '+collapsible_open_bg_color" :style="'border-style: solid; border-color: '+bg_color+';'">
								<ul class="row">
									<li :style="link_padding" class="'col s12 '+option_hover" v-if="show_exporters_link"><a href="#" class="right dropdown-trigger col s12 tooltipped" :data-target="'exporters_dropdown'+id" data-position="right" :data-tooltip="languages[6]"><span :class="text_color" v-if="menu_status=='open' || mynav==true">{{ languages[6] }} &nbsp;</span><i :class="text_color+'material-icons right'" :style="link_padding+' width:20px; margin-bottom:10px;'">local_shipping</i></a></li>
									<li :style="link_padding" class="'col s12 '+option_hover" v-if="show_certificate_application_link || show_certificate_verification_link"><a href="#" class="right dropdown-trigger col s12 tooltipped" :data-target="'certificates_dropdown'+id" data-position="right" :data-tooltip="languages[7]"><span  :class="text_color" v-if="menu_status=='open' || mynav==true">{{ languages[7] }} </span><i  :class="text_color+'bi bi-award-fill right'" :style="'padding:16px; position: relative; top:-33px; margin-bottom: 10px; left:5px; width:20px; font-size:25px; height: 20px;'"></i></a></li>
									<li :style="link_padding" class="'col s12 '+option_hover" v-if="dia_link_display"><router-link class="sidemynav-close right col s12 tooltipped" to="/customs" data-position="right" :data-tooltip="languages[8]"><span :class="text_color" v-if="menu_status=='open' || mynav==true">{{ languages[8] }} &nbsp;</span><i :class="text_color+'material-icons right'" :style="link_padding+' width:20px; '">account_balance</i></router-link></li>
									<li :style="link_padding" class="'col s12 '+option_hover" v-if="dia_link_display"><router-link class="sidemynav-close right col s12 tooltipped" to="/designated_authorities" data-position="right" :data-tooltip="languages[9]"><span :class="text_color" v-if="menu_status=='open' || mynav==true">{{ languages[9] }} &nbsp;</span><i :class="text_color+'material-icons right'" :style="link_padding+' width:20px; '">domain</i></router-link></li>
								</ul>
							</div>
						</li>
						<li v-if="show_accounts_link || show_orgs_link || show_other_settings_link">
							<div class="collapsible-header" :style="'background-color:'+bg_color+'; border-style: solid; border-color: '+bg_color+';'"><a href="#" :class="option_hover" data-position="right" data-tooltip="ADMIN OPERATIONS"><b :class="font_color">{{ languages[10] }} <span v-if="menu_status=='open' || mynav==true"> {{ languages[11] }}</span></b></a></div>
							<div :class="'collapsible-body row '+collapsible_open_bg_color" :style="'border-style: solid; border-color: '+bg_color+';'">
								<ul class="row">
									<li :style="link_padding" class="'col s12 '+option_hover" v-if="show_accounts_link"><a href="#" class="right dropdown-trigger col s12 tooltipped" :data-target="'accounts_dropdown'+id" data-position="right" :data-tooltip="languages[12]"><span v-if="menu_status=='open' || mynav==true" :class="text_color">{{ languages[12] }} </span><i :class="text_color+'material-icons right'" :style="link_padding+' width:20px; '">account_circle</i></a></li>
									<li :style="link_padding" class="'col s12 '+option_hover" v-if="show_orgs_link"><a href="#" class="right dropdown-trigger col s12 tooltipped" :data-target="'organizations_dropdown'+id" data-position="right" :data-tooltip="languages[13]"><span v-if="menu_status=='open' || mynav==true" :class="text_color">{{ languages[13] }} </span><i :class="text_color+'material-icons right'" :style="link_padding+' width:20px; '">location_city</i></a></li>
									<li :style="link_padding" class="'col s12 '+option_hover" v-if="show_other_settings_link"><a href="#" class="right dropdown-trigger col s12 tooltipped" :data-target="'other_items_dropdown'+id" data-position="right" :data-tooltip="languages[14]"><span  :class="text_color" v-if="menu_status=='open' || mynav==true">{{ languages[14] }} </span><i :class="text_color+'material-icons right'" :style="link_padding+' width:20px; '">settings</i></a></li>
								</ul>
							</div>
						</li>
						<li :style="'border-style: solid; border-color: '+bg_color+';'">
							<div class="collapsible-header" :style="'background-color:'+bg_color+'; border-style: solid; border-color: '+bg_color+';'"><a href="#" :class="option_hover" data-position="right" data-tooltip="INFORMATION CENTRE"><b :class="font_color"><span v-if="menu_status=='open' || mynav==true"> {{ languages[15] }} </span><span v-else>{{ languages[16] }}</span></b></a></div>
							<div :class="'collapsible-body row '+collapsible_open_bg_color" :style="'border-style: solid; border-color: '+bg_color+';'">
								<ul class="row">
									<li :style="link_padding" class="'col s12 '+option_hover"><router-link class="sidemynav-close right col s12 tooltipped" to="/" data-position="right" :data-tooltip="languages[17]"><i :class="text_color+'material-icons right'" :style="link_padding+' width:20px; '">web</i><span :class="text_color+' truncate right'" v-if="menu_status=='open' || mynav==true">&nbsp;{{ languages[17] }}&nbsp;</span></router-link></li>
									<li :style="link_padding" class="'col s12 '+option_hover"><router-link class="sidemynav-close right col s12 tooltipped" to="/news" data-position="right" :data-tooltip="languages[18]"><i :class="text_color+'bi bi-megaphone-fill right bigger-tiny'" :style="'padding:16px; margin-bottom: 10px; position: relative; top:-33px; left:5px; width:20px; font-size:25px; height: 20px;'"></i><span :class="text_color+' truncate right'" v-if="menu_status=='open' || mynav==true">&nbsp;{{ languages[18] }}&nbsp;</span></router-link></li>
									<li :style="link_padding" class="'col s12 '+option_hover"><router-link class="sidemynav-close right col s12 tooltipped" to="/guides" data-position="right" :data-tooltip="languages[19]"><i :class="text_color+'bi bi-file-pdf right bigger-tiny'" :style="'padding:16px; position: relative; top:-33px; left:5px; width:20px; font-size:25px; height: 20px;'"></i><span :class="text_color+' truncate right'" v-if="menu_status=='open' || mynav==true">&nbsp;{{ languages[19] }}&nbsp;</span></router-link></li>
								</ul>
							</div>
						</li><br><br>
					</ul>
				</div>
			</li>
		</ul>
		
		<ul :id="'accounts_dropdown'+id" :class="dropdown_option_color+'dropdown-content z-depth-3'">
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_roles_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/accounts/roles">{{ languages[20] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_user_permissions_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/accounts/permissions">{{ languages[21] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_users_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/accounts/users">{{ languages[22] }}</router-link></li>
		</ul>
		
		<ul :id="'organizations_dropdown'+id" :class="dropdown_option_color+'dropdown-content z-depth-3'">
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_orgtypes_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/organizations/org_types">{{ languages[23] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_org_details_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/organizations/orgs">{{ languages[24] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_apikeys_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/organizations/access_tokens">{{ languages[25] }}</router-link></li>
		</ul>
		<!--
		<ul :id="'api_keys_dropdown'+id" :class="dropdown_option_color+'dropdown-content z-depth-3'">
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_apikeys_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/api_keys/keys">{{ languages[26] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_apisubscriptions"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/api_keys/subscriptions">{{ languages[27] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_apipermissions_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/api_keys/permissions">{{ languages[28] }}</router-link></li>
		</ul>
		-->
		
		<ul :id="'certificates_dropdown'+id" :class="dropdown_option_color+'dropdown-content z-depth-3'">
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_certificate_application_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/certificates/application">{{ languages[29] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_certificate_verification_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/certificates/verification">{{ languages[30] }}</router-link></li>
		</ul>
		
		<ul :id="'other_items_dropdown'+id" :class="dropdown_option_color+'dropdown-content z-depth-3'">
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_guideperms_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/guides">{{ languages[31] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_newsperms_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/news">{{ languages[32] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_unitperms_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/units">{{ languages[33] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_hscodes_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/hs_codes">{{ languages[34] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_countryperms_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/countries">{{ languages[35] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_currencies_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/currencies">{{ languages[36] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_markets_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/intended_markets">{{ languages[37] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_origin_criteria_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/origin_criteria">{{ languages[38] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="show_package_kinds_link"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/other/package_kinds">{{ languages[39] }}</router-link></li>
		</ul>
		
		<ul :id="'exporters_dropdown'+id" :class="dropdown_option_color+'dropdown-content z-depth-3'">
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="add_exporter_perms"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/exporters/applications/add">{{ languages[40] }}</router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="get_exporter_perms"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/exporters/applications">{{ languages[41] }} </router-link></li>
			<li :class="dropdown_option_color+'hoverable li_spacer'" v-if="get_exporter_products_perms"><router-link :style="menu_font_size" :class="text_color+' sidemynav-close'" to="/exporters/products">{{ languages[42] }}</router-link></li>
		</ul>
	
</template>

<script>
import router from '@/router'
import M from '@cutls/materialize-css';
import IndexedDBHelper from '@/assets/js/IndexedDB'

import CurrentUser from '@/assets/js/CurrentUser'
import Configs from '@/assets/js/Configs'

import language from '@/configs/language.json';

export default {
	name: 'MySideBar',
	props: ['show_background', 'mynav', 'hidenav'],
	data() {
		return {
			db: null, //the database helper instance
			id: '',
			bg_color: '#000',
			menu_font_size: 'font-size: 12px;',
			font_color: 'blue-text text-lighten-3',
			font_color2: 'white-text text-darken-2',
			show_exporters_link: false,
			add_exporter_perms: false,
			get_exporter_perms: false,
			get_exporter_products_perms: false,
			show_certificate_application_link: false,
			show_certificate_verification_link: false,
			show_operations_link: false,
			show_admin_hr: false,
			show_accounts_link: false,
			show_orgs_link: false,
			show_other_settings_link: false,
			show_roles_link: false,
			show_user_permissions_link: false,
			show_users_link: false,
			show_orgtypes_link: false,
			show_org_details_link: false,
			show_apikeys_link: false,
			show_apisubscriptions: false,
			show_apipermissions_link: false,
			show_apipermissions_link: false,
			show_guideperms_link: false,
			show_newsperms_link: false,
			show_unitperms_link: false,
			show_hscodes_link: false,
			show_countryperms_link: false,
			show_currencies_link: false,
			show_markets_link: false,
			show_origin_criteria_link: false,
			show_package_kinds_link: false,

			menu_status: '',
			dropdown_option_color: '',
			collapsible_open_bg_color: '',
			option_hover: ' option_hover ',
			text_color: '',
			link_padding: '',
			link_icon_style: '',
			dia_link_display: null,
			perms: null,
			user: null,
			curr_user: null,
			login_form: true,
			verify_form: false,
			exporter_perms: null,
			user_perms: null,
			org_perms: null,
			certificate_perms: null,
			other_perms: null,

			link_options: '',
			user_firstname: null,
			user_lastname: null,
			user_role: null,

			eco_verification: "",
			input_validation: "",

			user_email: "",
			user_password: "",
			user_err: "",
			pre_response: true,
			during_response: false,
			post_response: false,
			
			mylang: 'en',
			languages: [],
		};
	},		
	methods: {	
		async initFunctions(){
			let mylang = await this.db.getStorageItem("mylang");
			if(mylang=="" || mylang==null || mylang===undefined)
			{
				mylang = 'en';
			}
			this.mylang = mylang;
			this.languages = language[mylang].comp__mysidenav;
		},
		initData(){
			this.initFunctions();
			this.setCSS();
			this.setMenuStatus();
			this.setPermissions();
			
			M.AutoInit();
		},
		setCSS(){
			if (this.mynav == true) {
			  this.link_padding = 'font-size:11px; padding:10px;';
			  this.text_color = 'white-text ';
			  this.dropdown_option_color = 'blue accent-4 ';
			  this.link_icon_style = 'font-size:20px; position:relative; top: 0px; height:20px;';
			  this.id = 2;
			} else {
			  this.link_padding = 'padding:10px;';
			  this.text_color = 'white-text text-lighten-1 ';
			  this.dropdown_option_color = 'blue accent-4 ';
			  this.link_icon_style = 'font-size:20px; position:relative; top: -15px; left:-3px; height:20px;';
			  this.id = 1;
			}
		},
		async setMenuStatus(){
			let myConfigs = new Configs();
			let styles = await myConfigs.menuStyles();
			this.menu_status = styles.menu_status;
			this.bg_color = styles.bg_color;
			this.collapsible_open_bg_color = ' blue darken-4 ';
		},
		async setPermissions(){
			let token = await this.db.getStorageItem("token");
			if(token != null && token !="")
			{
				this.token = token;
				
				let currUser = new CurrentUser();
				this.dia_link_display = await currUser.diaLinkDisplay();
				let curr_user = await currUser.account();
				this.curr_user = curr_user;
				
				let perms = await currUser.permissions();
				this.perms = perms;
				
				this.user_email = curr_user.email;
				this.user_firstname = curr_user.first_name;
				this.user_lastname = curr_user.last_name;
				this.user_role = curr_user.user_role;
				  
				let user_perms = perms.users;
				let org_perms = perms.orgs;
				let certificate_perms = perms.certificates;
				let other_perms = perms.other;
				let api_perms = perms.apis;
				let exporter_perms = perms.exporters;
				
				this.user_perms = user_perms;
				this.org_perms = perms.orgs;
				this.certificate_perms = perms.certificates;
				this.other_perms = perms.other;
				this.api_perms = perms.apis;
				
				if(perms.exporters.applications.add_data=='1' || perms.exporters.applications.get_data=='1' || perms.exporters.applications.edit_data=='1' || perms.exporters.applications.delete_data=='1' || perms.exporters.products.get_data=='1')
				{
					this.show_exporters_link = true;
				}
				
				if(exporter_perms.applications.add_data=='1')
				{
					this.add_exporter_perms = true;
				}
				
				if(exporter_perms.applications.get_data=='1')
				{
					this.get_exporter_perms = true;
				}
				
				if(exporter_perms.products.get_data=='1')
				{
					this.get_exporter_products_perms = true;
				}
				
				if(certificate_perms.application.add_data=='1' || certificate_perms.application.get_data=='1' || certificate_perms.application.edit_data=='1' || certificate_perms.application.delete_data=='1')
				{
					this.show_certificate_application_link = true;
				}
				
				if(certificate_perms.verification.add_data=='1' || certificate_perms.verification.get_data=='1' || certificate_perms.verification.edit_data=='1' || certificate_perms.verification.delete_data=='1')
				{
					this.show_certificate_verification_link = true;
				}
				
				if((user_perms.accounts.add_data=='1' || user_perms.accounts.edit_data=='1' || user_perms.accounts.delete_data=='1') || (user_perms.roles.add_data=='1' || user_perms.roles.edit_data=='1' || user_perms.roles.delete_data=='1') || (user_perms.permissions.add_data=='1' || user_perms.permissions.edit_data=='1' || user_perms.permissions.delete_data=='1') || (org_perms.branches.add_data=='1' || org_perms.branches.edit_data=='1' || org_perms.branches.delete_data=='1') || (org_perms.orgs.add_data=='1' || org_perms.orgs.edit_data=='1' || org_perms.orgs.delete_data=='1') || (org_perms.org_types.add_data=='1' || org_perms.org_types.edit_data=='1' || org_perms.org_types.delete_data=='1') || (other_perms.countries.add_data=='1' || other_perms.countries.edit_data=='1' || other_perms.countries.delete_data=='1') || (other_perms.currencies.add_data=='1' || other_perms.currencies.edit_data=='1' || other_perms.currencies.delete_data=='1') || (other_perms.guides.add_data=='1' || other_perms.guides.edit_data=='1' || other_perms.guides.delete_data=='1') || (other_perms.hs_codes.add_data=='1' || other_perms.hs_codes.edit_data=='1' || other_perms.hs_codes.delete_data=='1') || (other_perms.news.add_data=='1' || other_perms.news.edit_data=='1' || other_perms.news.delete_data=='1') || (other_perms.origin_criteria.add_data=='1' || other_perms.origin_criteria.edit_data=='1' || other_perms.origin_criteria.delete_data=='1') || (other_perms.units.add_data=='1' || other_perms.units.edit_data=='1' || other_perms.units.delete_data=='1') || (api_perms.keys.add_data=='1' || api_perms.keys.edit_data=='1' || api_perms.keys.delete_data=='1') || (api_perms.permissions.add_data=='1' || api_perms.permissions.edit_data=='1' || api_perms.permissions.delete_data=='1') || (api_perms.roles.add_data=='1' || api_perms.roles.edit_data=='1' || api_perms.roles.delete_data=='1') || (api_perms.subscriptions.add_data=='1' || api_perms.subscriptions.edit_data=='1' || api_perms.subscriptions.delete_data=='1'))
				{
					this.show_admin_hr = true;
				}
				
				if((user_perms.accounts.add_data=='1' || user_perms.accounts.edit_data=='1' || user_perms.accounts.delete_data=='1') || (user_perms.roles.add_data=='1' || user_perms.roles.edit_data=='1' || user_perms.roles.delete_data=='1') || (user_perms.permissions.add_data=='1' || user_perms.permissions.edit_data=='1' || user_perms.permissions.delete_data=='1') || (org_perms.branches.add_data=='1' || org_perms.branches.edit_data=='1' || org_perms.branches.delete_data=='1') || (org_perms.orgs.add_data=='1' || org_perms.orgs.edit_data=='1' || org_perms.orgs.delete_data=='1') || (org_perms.org_types.add_data=='1' || org_perms.org_types.edit_data=='1' || org_perms.org_types.delete_data=='1') || (other_perms.countries.add_data=='1' || other_perms.countries.edit_data=='1' || other_perms.countries.delete_data=='1') || (other_perms.currencies.add_data=='1' || other_perms.currencies.edit_data=='1' || other_perms.currencies.delete_data=='1') || (other_perms.guides.add_data=='1' || other_perms.guides.edit_data=='1' || other_perms.guides.delete_data=='1') || (other_perms.hs_codes.add_data=='1' || other_perms.hs_codes.edit_data=='1' || other_perms.hs_codes.delete_data=='1') || (other_perms.news.add_data=='1' || other_perms.news.edit_data=='1' || other_perms.news.delete_data=='1') || (other_perms.origin_criteria.add_data=='1' || other_perms.origin_criteria.edit_data=='1' || other_perms.origin_criteria.delete_data=='1') || (other_perms.units.add_data=='1' || other_perms.units.edit_data=='1' || other_perms.units.delete_data=='1') || (api_perms.keys.add_data=='1' || api_perms.keys.edit_data=='1' || api_perms.keys.delete_data=='1') || (api_perms.permissions.add_data=='1' || api_perms.permissions.edit_data=='1' || api_perms.permissions.delete_data=='1') || (api_perms.roles.add_data=='1' || api_perms.roles.edit_data=='1' || api_perms.roles.delete_data=='1') || (api_perms.subscriptions.add_data=='1' || api_perms.subscriptions.edit_data=='1' || api_perms.subscriptions.delete_data=='1'))
				{
					this.show_operations_link = true;
				}
				
				if((user_perms.accounts.add_data=='1' || user_perms.accounts.edit_data=='1' || user_perms.accounts.delete_data=='1') || (user_perms.roles.add_data=='1' || user_perms.roles.edit_data=='1' || user_perms.roles.delete_data=='1') || (user_perms.permissions.add_data=='1' || user_perms.permissions.edit_data=='1' || user_perms.permissions.delete_data=='1'))
				{
					this.show_accounts_link = true;
				}
				
				if((org_perms.branches.add_data=='1' || org_perms.branches.edit_data=='1' || org_perms.branches.delete_data=='1') || (org_perms.orgs.add_data=='1' || org_perms.orgs.edit_data=='1' || org_perms.orgs.delete_data=='1') || (org_perms.org_types.add_data=='1' || org_perms.org_types.edit_data=='1' || org_perms.org_types.delete_data=='1'))
				{
					this.show_orgs_link = true;
				}
				
				if((other_perms.countries.add_data=='1' || other_perms.countries.edit_data=='1' || other_perms.countries.delete_data=='1') || (other_perms.currencies.add_data=='1' || other_perms.currencies.edit_data=='1' || other_perms.currencies.delete_data=='1') || (other_perms.guides.add_data=='1' || other_perms.guides.edit_data=='1' || other_perms.guides.delete_data=='1') || (other_perms.hs_codes.add_data=='1' || other_perms.hs_codes.edit_data=='1' || other_perms.hs_codes.delete_data=='1') || (other_perms.news.add_data=='1' || other_perms.news.edit_data=='1' || other_perms.news.delete_data=='1') || (other_perms.origin_criteria.add_data=='1' || other_perms.origin_criteria.edit_data=='1' || other_perms.origin_criteria.delete_data=='1') || (other_perms.units.add_data=='1' || other_perms.units.edit_data=='1' || other_perms.units.delete_data=='1'))
				{
					this.show_other_settings_link = true;
				}
				
				if(user_perms.roles.add_data=='1' || user_perms.roles.edit_data=='1' || user_perms.roles.delete_data=='1')
				{
					this.show_roles_link = true;
				}
				
				if(user_perms.permissions.add_data=='1' || user_perms.permissions.edit_data=='1' || user_perms.permissions.delete_data=='1')
				{
					this.show_user_permissions_link = true;
				}
				
				if(user_perms.accounts.add_data=='1' || user_perms.accounts.edit_data=='1' || user_perms.accounts.delete_data=='1')
				{
					this.show_users_link = true;
				}
				
				if(org_perms.org_types.add_data=='1' || org_perms.org_types.edit_data=='1' || org_perms.org_types.delete_data=='1')
				{
					this.show_orgtypes_link = true;
				}
				
				if(org_perms.orgs.add_data=='1' || org_perms.orgs.edit_data=='1' || org_perms.orgs.delete_data=='1')
				{
					this.show_org_details_link = true;
				}
				
				if(api_perms.keys.add_data=='1' || api_perms.keys.get_data=='1' || api_perms.keys.edit_data=='1' || api_perms.keys.delete_data=='1')
				{
					this.show_apikeys_link = true;
				}
				
				if(api_perms.subscriptions.add_data=='1' || api_perms.subscriptions.get_data=='1' || api_perms.subscriptions.edit_data=='1' || api_perms.subscriptions.delete_data=='1')
				{
					this.show_apisubscriptions = true;
				}
				
				if(api_perms.permissions.add_data=='1' || api_perms.permissions.get_data=='1' || api_perms.permissions.edit_data=='1' || api_perms.permissions.delete_data=='1')
				{
					this.show_apipermissions_link = true;
				}
				
				if(other_perms.guides.add_data=='1' || other_perms.guides.edit_data=='1' || other_perms.guides.delete_data=='1')
				{
					this.show_guideperms_link = true;
				}
				
				if(other_perms.news.add_data=='1' || other_perms.news.edit_data=='1' || other_perms.news.delete_data=='1')
				{
					this.show_newsperms_link = true;
				}
				
				if(other_perms.units.add_data=='1' || other_perms.units.edit_data=='1' || other_perms.units.delete_data=='1')
				{
					this.show_unitperms_link = true;
				}
				
				if(other_perms.hs_codes.add_data=='1' || other_perms.hs_codes.edit_data=='1' || other_perms.hs_codes.delete_data=='1')
				{
					this.show_hscodes_link = true;
				}
				
				if(other_perms.countries.add_data=='1' || other_perms.countries.edit_data=='1' || other_perms.countries.delete_data=='1')
				{
					this.show_countryperms_link = true;
				}
				
				if(other_perms.currencies.add_data=='1' || other_perms.currencies.edit_data=='1' || other_perms.currencies.delete_data=='1')
				{
					this.show_currencies_link = true;
				}
				
				if(other_perms.intended_markets.add_data=='1' || other_perms.intended_markets.edit_data=='1' || other_perms.intended_markets.delete_data=='1')
				{
					this.show_markets_link = true;
				}
				
				if(other_perms.origin_criteria.add_data=='1' || other_perms.origin_criteria.edit_data=='1' || other_perms.origin_criteria.delete_data=='1')
				{
					this.show_origin_criteria_link = true;
				}
				
				if(other_perms.package_kinds.add_data=='1' || other_perms.package_kinds.edit_data=='1' || other_perms.package_kinds.delete_data=='1')
				{
					this.show_package_kinds_link = true;
				}
			}
		},
		toggleMenu() {
		  let menu_status;
		  if (this.menu_status == 'open') {
			menu_status = 'closed';
			this.option_hover = ' option_hover tooltipped ';
		  } else {
			menu_status = 'open';
			this.option_hover = ' option_hover ';
		  }
		  
		  let myConfigs = new Configs();
		  this.menu_status = myConfigs.menuState(menu_status);
		  this.$emit('menu_status', this.menu_status);
		},
	},
	async created() { this.db = new IndexedDBHelper("my-eco-db", 1); await this.db.open(); }, mounted () {
		this.initData();
	},
}
</script>
<style scoped>
li {
  font-size: 11px;
}
.option_hover:hover { 
	background-color: #0041EC; 
}
.li_spacer {
margin-top:6px;
margin-bottom:8px;
}
.scroll {
            overflow-x: hidden;
            overflow-y: true;
        }
</style>