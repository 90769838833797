import { createApp } from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import '@/../public/css/materialize.min.css'
import '@/../public/css/css.css?family=Inconsolata'
import '@/../public/css/icon.css'
import '@/../public/css/style.css'
import '@/../public/css/bootstrap-icons.css'
import '@/../public/js/jquery.min'
import '@/../public/js/materialize.min'

createApp(App).use(router).mount('#app')
